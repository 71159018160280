import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import * as styles from "../styles/firm-philosophy.module.css"
import Sidebar from "../components/Sidebar"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function FirmPhilosophy({ data }) {
  return (
    <Layout>
      <GatsbySeo
        title="Firm Philosophy | Tecklenburg, Jenkins & Jacoby, LLC | Charleston, SC"
        description="We believe that attorneys should resolve problems and not aggravate
            them, that an attorney should be an asset and not just an expense,
            and that our clients’ goals must be taken as our own. We are
            resourceful. We are resolute. We will always go the extra mile for
            our clients."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/firm-philosophy/"
      />
      <section className={styles.header}>
        <section>
          <Sidebar data={data} />
        </section>
        <section className={styles.content}>
          <h1>Tecklenburg, Jenkins & Jacoby Firm Philosophy</h1>
          <p>
            We believe that attorneys should resolve problems and not aggravate
            them, that an attorney should be an asset and not just an expense,
            and that our clients’ goals must be taken as our own. We are
            resourceful. We are resolute. We will always go the extra mile for
            our clients.
          </p>
          <strong>
            Tecklenburg, Jenkins & Jacoby was Founded on the Following Beliefs:
          </strong>
          <h2>
            #1 Effective Approach to{" "}
            <Link to="/practice-areas/south-carolina-litigation">
              Litigation
            </Link>
          </h2>
          <p>
            The core belief upon which Tecklenburg, Jenkins & Jacoby was built
            upon is expressed well in the words of{" "}
            <a href="https://en.wikipedia.org/wiki/Clarence_Darrow">
              Clarence Darrow
            </a>
            : “The only real lawyers are trial lawyers…” Our adversaries know
            that we only take cases of merit. We have the confidence to pursue
            our clients’ causes through to the end – including to trial, if
            necessary. Our clients are well-prepared for deciding how far to
            take their cases in order to obtain the desired results.
          </p>
          <h2>#2 Strong Client Relationships</h2>
          <p>
            Our firm’s philosophy centers heavily on building positive
            relationships with our clients. We believe that it is crucial to
            keep our clients involved every step of the way. One of the most
            frequent complaints you hear about lawyers is that they do not keep
            their clients updated. At Tecklenburg, Jenkins & Jacoby, we pride
            ourselves on our strong client relationships and commitment to
            communication. We understand that client input is invaluable, and we
            rely on their knowledge, interest, and insight during each step of
            the legal process. Our clients can rely on us for prompt, reliable
            communication.
          </p>
          <h2># 3 Comprehensive Approach</h2>
          <p>
            <Link to="/attorneys">Our attorneys</Link> are thorough. At
            Tecklenburg, Jenkins & Jacoby, our lawyers understand that sometimes
            the evidence that proves the most valuable in litigation is
            discovered in the least likely place. We identify high leverage
            aspects of cases. The best way to do this is to focus on angles
            other attorneys might ignore.
          </p>
          <h2>#4 Respect</h2>
          <p>
            All participants in the legal process should be treated with respect
            and the proper level of professionalism. This is not just a belief,
            but it also is our professional responsibility. We will never allow
            our enthusiasm for the practice of law to compromise our behavior,
            ethics, or integrity. We believe that fair, courteous treatment of
            all parties involved helps to facilitate a positive outcome for our
            clients. Maintaining respect for the legal system and all of its
            participants tempers much of the unnecessary discord that can derail
            progress towards positive resolutions. We pride ourselves on our
            ability to fight for your rights without compromising integrity and
            reputation.
          </p>
          <h2>#5 Commitment</h2>
          <p>
            Perhaps the most important philosophy of Tecklenburg, Jenkins &
            Jacoby is our commitment to our clients and dedication to their
            goals. According to Black’s Law Dictionary, loyalty is “faithfulness
            or allegiance to a person, cause, duty or government.” We know that
            each client has many choices when hiring a law firm, and we
            understand that serving your needs means treating your problems as
            our own, and faithfully pursuing your goals.
          </p>
          <p>
            <Link to="/contact">Contact Us</Link> Today!
          </p>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query PhilosophySidebarImages {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "firm-philosophy" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
